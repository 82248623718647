import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import { Link } from "gatsby";
import Footer from "./Footer";
import { Context } from "../utils/context";
import { Helmet } from "react-helmet";

// Icons here
import SwifUpIcon from "../assets/images/Icons/swif-up-icon.svg";
import Chatbot from "./Chatbot";

export default function Layout({ children }) {
  const loc = window.location.pathname;
  const [activeItem, setActiveItem] = useState(null);

  // const canonicalUrl = `https://apprely.com${window.location?.pathname || ""}`;

  // console.log('check canonicalUrl', canonicalUrl);

  const navComponents = [
    // {
    //   route: '/',
    //   label: 'Home',
    // },
    {
      route: "/aboutus",
      label: "About Us",
    },
    {
      route: "/solutions",
      label: "Solutions",
    },
    {
      route: "/blogs",
      label: "Case Study",
    },
    {
      route: "/careers",
      label: "Careers",
    },
    // {
    //   route: "/casestudy",
    //   label: "Case Study",
    // },
    // {
    //   route: "/timerelypage",
    //   label: "Products",
    // },
    // {
    //   route: "/admin",
    //   label: "admin",
    // },
    {
      route: "/contactus",
      label: "Contact Us",
    },
    {
      route: "/gmail-connector-guide",
      label: "How to use gmail connector",
    },
    {
      route: "/terms-of-services-connector",
      label: "Terms of services for gmail connector",
    },
    {
      route: "/terms-of-services-timerely",
      label: "Terms of services for timerely",
    },
    {
      route: "/timerely-guide",
      label: "timerely",
    },
  ];

  useEffect(() => {
    const cursorElements = document.querySelectorAll(".cursor");
    const links = document.querySelectorAll(".link");

    const handleMouseMove = (e) => {
      let x = e.pageX;
      let y = e.pageY;

      cursorElements.forEach((el) => {
        el.style.left = `${x}px`;
        el.style.top = `${y}px`;

        links.forEach((link) => {
          link.addEventListener("mouseenter", () => {
            el.classList.add("hover");
          });
          link.addEventListener("mouseleave", () => {
            el.classList.remove("hover");
          });
        });
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);

      // Clean up event listeners for links
      links.forEach((link) => {
        link.removeEventListener("mouseenter", () => {
          cursorElements.forEach((el) => {
            el.classList.add("hover");
          });
        });
        link.removeEventListener("mouseleave", () => {
          cursorElements.forEach((el) => {
            el.classList.remove("hover");
          });
        });
      });
    };
  }, []);

  return (
    <div id="navbar-section">
      {/* <div class="cursor outer"></div>
      <div class="cursor inner"></div> */}

      <Helmet>
        {/* DO NOT DELETE THIS IS TRACKING LIVE USERS FROM salesloft */}
        <script type="text/javascript">
          {`(function(i,s,o,g,r,a,m){i['SLScoutObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://scout-cdn.salesloft.com/sl.js','slscout');
          slscout(["init", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0IjoxMDg3MTd9.EFAGwwU3G3OKpxIOns8qPi_hbK9vq8sTS-HTL6cqolU"]);`}
        </script>
      </Helmet>
      {loc != "/timerely-guide/" && 
        loc !== "/admin/" && 
        loc !== "/loginpage/" && 
        loc !== "/joblist/" && 
        loc !== "/hr-admin-login/" && 
        loc !== "/hr-admin-dashboard/" && 
        loc !== "/list-of-all-jobs/" && 
        loc !== "/job-application-form/" && 
        loc !== "/adminboard/" &&(
        <Nav
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          navComponents={navComponents}
        />
      )}
      <div className="">
        <Context.Provider value={setActiveItem}>{children}</Context.Provider>
      </div>
      {loc != "/timerely-guide/" &&
        loc !== "/hr-admin-login/" && 
        loc !== "/hr-admin-dashboard/" && 
        loc !== "/list-of-all-jobs/" && 
        loc !== "/job-application-form/" && (
        <Footer setActiveItem={setActiveItem} navComponents={navComponents} />
      )}

      {/* Conditionally render Chatbot component */}
      {loc !== "/hr-admin-login/" && 
       loc !== "/hr-admin-dashboard/" && 
       loc !== "/list-of-all-jobs/" && 
       loc !== "/job-application-form/" && (
        <div className="right-side__content back-to-top">
          <Chatbot />
        </div>
      )}
      {/* {loc != "/admin/" && (
        <Nav
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          navComponents={navComponents}
        />
      )}
      <div className="">
        <Context.Provider value={setActiveItem}>{children}</Context.Provider>
      </div>
      {loc != "/admin/" && (
        <Footer setActiveItem={setActiveItem} navComponents={navComponents} />
      )} */}
      {/* <div className="right-side__content back-to-top">
        <Link to="#navbar-section">
          <img
            className="animate__animated animate__rotateIn"
            src={SwifUpIcon}
            alt={SwifUpIcon}
            height="45"
            width="45"
          />
          <br />
        </Link>
      </div> */}

      {/* <div className="right-side__content back-to-top">
        <Chatbot />
      </div> */}
    </div>
  );
}
