import React, { useState, useEffect, useRef } from "react";
import { Button, Popover, Spin } from "antd"; // Importing Spin from antd for loader
import ky from "ky"; // Importing ky for API calls

import ChatbotIcon from "../assets/images/Icons/chatbot-icon.svg";
import SendIcon from "../assets/images/Icons/send-icon.svg";
import ChatbotAssistantIcon from "../assets/images/Icons/chatbot-assitant-icon.svg";
import Markdown from "react-markdown";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { handleSubmitChatbotUserForm } from "../services/firebase";

import "../styles/chatbot.scss";

const baseURL = "https://3.86.48.155";

const Chatbot = () => {
  const messagesEndRef = useRef(null)
  const inputRef = useRef(null); 

  const [isOpen, setIsOpen] = useState(false);
  const [sessionToken, setSessionToken] = useState("");
  const [messages, setMessages] = useState([ ]);
  const [userInput, setUserInput] = useState("");
  const [displayInput, setDisplayInput] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State for loader
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [userinfoAuth, setUserInfoAuth] = useState(false);
  const [username, setUserName] = useState("")
  const [useremail, setuserEmail] = useState("");

  const toggleChatbot = () => {
    setUserName("");
    setuserEmail("");
    setUserInfoAuth(false);
    setIsOpen(!isOpen);
    setSessionToken("");
    setErrorMessage("");
    // setMessages([{}]);
  };

  useEffect(() => {
    if (isOpen && !sessionToken) {
      const getSessionToken = async () => {
        try {
          const response = await ky
            .get(`${baseURL}/api/v1/chatbot/getSessionToken/`)
            .json();
          setSessionToken(response.session_token);
        } catch (error) {
          console.error("Error fetching session token:", error);
        }
      };
      getSessionToken();
    }
  }, [isOpen, sessionToken]);

  const handleSendMessage = async () => {
    // Reset error message
    setErrorMessage("");
    setDisplayInput("");
    // Validate user input
    if (!userInput.trim() && userinfoAuth) {
      setErrorMessage("Please enter a message."); // Set error message
      return;
    }

    if (isLoading) return; // Prevent sending if loader is active

    // Update user message in the chat
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: userInput },
    ]);

    setIsLoading(true); // Show loader while waiting for the response
    try {
      const response = await ky
        .post(`${baseURL}/api/v1/chatbot/getAIResponse/`, {
          json: {
            user_query: userInput,
            username: username,
            email: useremail
          },
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        })
        .json();

      // Update bot response in the chat
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: response.ai_response },
      ]);
    } catch (error) {
      console.error("Error fetching AI response:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Sorry, Please try again." },
      ]);
    }
    setIsLoading(false); // Hide loader after the response
    setUserInput("");
  };

  // useEffect(() => {
  //   setMessages([{ sender: "bot", text: `Hi ${username},Welcome to Apprely Technology. How can I assist you?` }]);
  //  },[userinfoAuth])

  const handleSubmit = async (values) => {
    setIsLoading(true)
    setUserName(values?.username)
    setuserEmail(values?.email)
    setMessages([
      { sender: "bot", text: "..." },
    ]);
    const reqData = {
      username: values?.username,
      email: values?.email,
    };
    try {
      const res = await handleSubmitChatbotUserForm(reqData);
      const response = await ky
        .post(`${baseURL}/api/v1/chatbot/getAIResponse/`, {
          json: {
            user_query: userInput || `Hi, My Name is ${values.username}`,
            username: values.username || " ",
            email: values.email || " ",
          },
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        })
        .json();

      // Update bot response in the chat
      setMessages([
        { sender: "bot", text: response.ai_response },
      ]);
    } catch (error) {
      console.error("Error fetching AI response:", error);
      setMessages([
        { sender: "bot", text: "Sorry, Please Try again" },
      ]);
    } finally {
      setUserInfoAuth(true)
      setIsLoading(false)
    }
    setUserInfoAuth(true)
    setIsLoading(false)
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage(); // Trigger message send on "Enter" press
    }
  };

  const content = (
    <div>
      <p className="m-0">Want to chat about AppRely’s</p>
      <p className="m-0">Services/products? I’m an AI bot</p>
      <p className="m-0">that’s here to help</p>
    </div>
  );

  const validationSchema = Yup.object({
    username: Yup.string().required('Name is required'), // 
  });

  // Scroll to the bottom of the message list when new messages are added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [userInput]); 

  return (
    <div className="chatbot-container">
      {isOpen ? (
        <div className="chatbot-window">
          <div className="chatbot-header">
            <h3>
              <img src={ChatbotAssistantIcon} alt="Chat Assistant Icon for AppRely Assistant" />
              <span className="ml-5">AppRely Assistant</span>
            </h3>
            <button onClick={toggleChatbot} className="close-button">
              &times;
            </button>
          </div>
          <div className="chatbot-messages scrollbar" id="style-3">
            {userinfoAuth ?
              (messages.map((msg, index) => (
                <div
                  key={index}
                  className={
                    msg.sender === "bot"
                      ? "chatbot-bot-messages"
                      : "chatbot-user-messages"
                  }
                >
                  <Markdown className={`chatbot-message ${msg.sender === "bot" ? "bot-message" : "user-message"
                    }`} >{msg.text}</Markdown>
                </div>
              ))) :
              (
                <div className="userInfoSection">
                  <div className="userTitle">
                    <span>Welcome to Apprely!!</span>
                  </div>
                  <div className="user-form">
                    <Formik
                      initialValues={{ username: "", email: "" }}
                      validationSchema={validationSchema}  
                      onSubmit={handleSubmit}
                    >
                      {(initialValues) => {
                        console.log('Initial Values:', initialValues);
                        return (
                          <Form className="user-info-form">
                            <div className="userField">
                              <div className="label">
                                <label htmlFor="username">Name</label>
                                <div className="importantField">*</div>
                              </div>
                              <Field type="text" id="username" name="username" className="userInputField" style={{ border: initialValues.errors.username ? '0.5px solid #FF4C4C' : '0.5px solid grey' }} />
                              <ErrorMessage  name="username" component="div" className="error-message" />
                            </div>

                            <div className="userField">
                              <div className="label">
                                <label htmlFor="email">Email</label>
                                {/* <div className="importantField">*</div> */}
                              </div>
                              <Field type="email" id="email" name="email" className="userInputField" />
                            </div>

                            <div className="userField">
                              <button
                                type="primary" htmlType="submit"
                                className="btn btn__primary-bg w-100 ap-fw__700" 
                                style={{ height: '38px', fontSize: '14px' }}
                                >
                                {isLoading ? (<Spin className="mr-10 mt-10" />):"Start Chat"}
                              </button>
                              {/* <Button type="primary" htmlType="submit"
                              size = "large" className="btn-primary">{
                                isLoading ? (<Spin className="mr-10 mt-10" />)
                                :"Start Chat"
                              }
                              </Button> */}
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
              )
            }
            <div ref={messagesEndRef} />
          </div>
          {
            !userinfoAuth ?
              (
                <div className="chatbot-input">
                  <input
                    type="text"
                    value={displayInput}
                    ref={inputRef}
                    onChange={(e) => {
                      setUserInput(e.target.value)
                      setDisplayInput(e.target.value)
                    }}
                    onKeyPress={handleKeyPress} // Listen for Enter key press
                    placeholder={"Add Name to Start Chat"}
                    disabled={true} // Disable input while loading
                  />
                </div>
              ) :
              (
                <div className="chatbot-input">
                  <input
                    type="text"
                    value={displayInput}
                    ref={inputRef}
                    onChange={(e) => {
                      setUserInput(e.target.value)
                      setDisplayInput(e.target.value)
                    }}
                    onKeyPress={handleKeyPress} // Listen for Enter key press
                    placeholder={"Type your message here..."}
                    disabled={isLoading} // Disable input while loading
                  />
                  {/* Show error message if input is invalid */}
                  {errorMessage && (
                    <div className="error-message" style={{ color: "red" }}>
                      {errorMessage}
                    </div>
                  )}
                  {/* Show loader instead of send icon when loading */}
                  {isLoading ? (
                    <Spin className="mr-10 mt-10" />
                  ) : (
                    <img
                      src={SendIcon}
                      alt="Send Icon"
                      className="mr-10 cursor-pointer"
                      onClick={handleSendMessage}
                    />
                  )}
                </div>
              )
          }
        </div>
      ) : (
        <div className="chatbot-icon" onClick={toggleChatbot}>
          <Popover content={content}>
            <div className="chat-icon">
              <img src={ChatbotIcon} alt="Chat Assistant Icon for AppRely Assistant" />
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
